import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta:{
      title: '上海墨砾新材料 - Nexmaterials'
    }

  },
  {
    path: '/About',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta:{
      title: '上海墨砾新材料 - Nexmaterials | 关于我们'
    }
  },
  {
    path: '/case/changzhou',
    name: 'CaseChangzhou',
    component: () => import('../views/CaseChangzhou.vue'),
    meta:{
      title: '上海墨砾新材料 - Nexmaterials | 常州工厂'
    }
  },
  {
    path: '/joinus',
    name: 'JoinUs',
    component: () => import('../views/JoinUs.vue'),
    meta:{
      title: '上海墨砾新材料 - Nexmaterials | 加入我们'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
