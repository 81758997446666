import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import 'element-plus/lib/theme-chalk/index.css'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'

const i18n = new createI18n({
    // 默认语言
    locale: localStorage.lang || 'zh',
    // 引入对应的语言包文件
    messages: {
        'zh': require('@/assets/languages/zh.json'),
        'en': require('@/assets/languages/en.json')
    }
});

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

createApp(App).use(i18n).use(ElementPlus).use(router).mount('#app')
