<template>
  <div id="app">
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
};
</script>
<style lang="scss">
$main-bgcolor: #1a1a1a;
* {
  margin: 0;
  border: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: $main-bgcolor;
  // background-color: yellow;
  min-height: 1000px;
}
.el-image__placeholder {
  background-color: $main-bgcolor;
}
.el-image__error {
  background-color: $main-bgcolor;
}
// #nav {

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }

@font-face {
  font-family: "iconfont"; /* Project id 2908945 */
  src: url("//at.alicdn.com/t/font_2908945_lf9vr5cmfxd.woff2?t=1636444352016")
      format("woff2"),
    url("//at.alicdn.com/t/font_2908945_lf9vr5cmfxd.woff?t=1636444352016")
      format("woff"),
    url("//at.alicdn.com/t/font_2908945_lf9vr5cmfxd.ttf?t=1636444352016")
      format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-jiazhi:before {
  content: "\e619";
}

.icon-cangchucangku:before {
  content: "\e98c";
}

.icon-biaoqianlan_huoban:before {
  content: "\e60e";
}

.icon-bumen:before {
  content: "\e610";
}

.icon-qian:before {
  content: "\e616";
}

.icon-goutong:before {
  content: "\e618";
}

.icon-goutong_quxiao:before {
  content: "\e61a";
}

.icon-guanli:before {
  content: "\e993";
}

.icon-shujujianguan:before {
  content: "\e99b";
}
</style>
